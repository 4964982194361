@charset "UTF-8";
/* --------------- variables --------------------- */
/* Break Point */
/* Color */
/*サイトカラー*/
/*サイトカラー*/
/*テキストに使うフォントカラー*/
/*テキスト内で使うリンクカラー*/
/* font */
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap");
@media screen and (max-width: 800px) {
  .main_img {
    background: url(../images/home/top_main_sp.png) no-repeat;
    width: 100%;
    height: 496px;
    background-size: cover;
    background-position: center;
    text-align: center;
    padding-top: 146px; }
    .main_img img {
      width: 111px; }
  #contents {
    padding-top: 55px; }
  h2 {
    color: #028287;
    font-weight: bold;
    line-height: 1.3;
    position: relative;
    text-align: center;
    font-size: 23px;
    padding-bottom: 10px; }
    h2:before {
      content: "";
      height: 2px;
      background-color: #0ea7ad;
      position: absolute;
      bottom: 0;
      width: 80px;
      left: calc(50% - 40px); }
  .news_list {
    margin-top: 35px; }
    .news_list dl:not(:first-of-type) {
      margin-top: 25px; }
    .news_list dl dt {
      color: #666666;
      background-color: #eceff1;
      text-align: center;
      line-height: 1.2;
      padding: 6px;
      display: inline-block;
      font-size: 14px;
      width: 100px; }
    .news_list dl dd {
      margin-top: 10px; }
      .news_list dl dd a {
        text-decoration: none;
        color: #000; }
        .news_list dl dd a:hover {
          text-decoration: underline; }
  .more_btn {
    margin-top: 40px;
    text-align: center; }
    .more_btn a {
      text-decoration: none;
      color: #028287;
      border: 1px solid #0ea7ad;
      display: inline-block;
      text-align: center;
      padding: 10px 15px;
      width: 60%; }
  #introduction h2 + p {
    margin-top: 30px;
    line-height: 2; }
  #introduction .btn_list {
    margin-top: 40px;
    text-align: center; }
    #introduction .btn_list > div:not(:first-of-type) {
      margin-top: 10px; }
  #activities {
    padding-bottom: 50px; }
    #activities .activities_slide {
      margin-top: 35px; }
      #activities .activities_slide .item .img img {
        max-width: 100%; }
      #activities .activities_slide .item .text {
        background-color: #eceff1;
        text-align: center;
        padding: 20px 15px 25px; }
        #activities .activities_slide .item .text .tuki {
          color: #028287;
          font-weight: bold;
          font-family: "Lato", sans-serif;
          display: inline-block;
          position: relative;
          font-size: 35px;
          padding-right: 30px; }
          #activities .activities_slide .item .text .tuki:after {
            content: "月";
            font-weight: normal;
            position: absolute;
            bottom: 2px;
            right: 0px;
            margin-right: 10px;
            font-size: 15px; }
        #activities .activities_slide .item .text .title {
          display: inline-block;
          padding-bottom: 3px; }
        #activities .activities_slide .item .text .more_btn {
          margin-top: 15px; }
    #activities button {
      background-color: transparent;
      border: none;
      cursor: pointer;
      outline: none;
      padding: 0;
      appearance: none; }
    #activities .slick-prev {
      position: absolute;
      background: url(../images/common/slide_arrow_left_sp.png) no-repeat;
      width: 35px;
      height: 35px;
      background-size: cover;
      left: -8px;
      top: calc(20%);
      z-index: 2; }
    #activities .slick-next {
      position: absolute;
      background: url(../images/common/slide_arrow_right_sp.png) no-repeat;
      width: 35px;
      height: 35px;
      background-size: cover;
      right: -8px;
      top: calc(20%);
      z-index: 2; }
    #activities .slick-dots {
      margin-top: 20px;
      margin-top: 10px; }
      #activities .slick-dots li {
        display: inline-block;
        margin: 0;
        width: 50px;
        height: 7px;
        margin-right: 6px; }
        #activities .slick-dots li button {
          position: relative;
          text-indent: -9999px; }
          #activities .slick-dots li button:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            background-color: #d8d8d8;
            width: 50px;
            height: 7px; }
        #activities .slick-dots li.slick-active button:before {
          background-color: #0ea7ad; }
  /* Slider */
  .slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent; }
  .slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0; }
    .slick-list:focus {
      outline: none; }
    .slick-list.dragging {
      cursor: pointer;
      cursor: hand; }
  .slick-slider .slick-track,
  .slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  .slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto; }
    .slick-track:before, .slick-track:after {
      content: "";
      display: table; }
    .slick-track:after {
      clear: both; }
    .slick-loading .slick-track {
      visibility: hidden; }
  .slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    display: none; }
    [dir="rtl"] .slick-slide {
      float: right; }
    .slick-slide img {
      display: block; }
    .slick-slide.slick-loading img {
      display: none; }
    .slick-slide.dragging img {
      pointer-events: none; }
    .slick-initialized .slick-slide {
      display: block; }
    .slick-loading .slick-slide {
      visibility: hidden; }
    .slick-vertical .slick-slide {
      display: block;
      height: auto;
      border: 1px solid transparent; }
  .slick-arrow.slick-hidden {
    display: none; } }

@media screen and (min-width: 800px) {
  h2 {
    color: #028287;
    font-weight: bold;
    line-height: 1.3;
    position: relative;
    text-align: center; }
    h2:before {
      content: "";
      height: 2px;
      background-color: #0ea7ad;
      position: absolute;
      bottom: 0; }
  .news_list dl dt {
    color: #666666;
    background-color: #eceff1;
    text-align: center;
    line-height: 1.2;
    padding: 6px; }
  .news_list dl dd a {
    text-decoration: none;
    color: #000; }
    .news_list dl dd a:hover {
      text-decoration: underline; }
  .more_btn a {
    text-decoration: none;
    color: #028287;
    border: 1px solid #0ea7ad;
    display: inline-block;
    text-align: center; }
  #introduction .btn_list > div:not(:first-of-type) {
    margin-top: 10px; }
  #activities .activities_slide .item .img img {
    max-width: 100%; }
  #activities .activities_slide .item .text .tuki {
    color: #028287;
    font-weight: bold;
    font-family: "Lato", sans-serif;
    display: inline-block;
    position: relative; }
    #activities .activities_slide .item .text .tuki:after {
      content: "月";
      font-weight: normal;
      position: absolute;
      bottom: 2px;
      right: 0px; }
  #activities button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    padding: 0;
    appearance: none; }
  #activities .slick-prev {
    position: absolute; }
  #activities .slick-next {
    position: absolute; }
  #activities .slick-dots {
    margin-top: 20px; }
    #activities .slick-dots li {
      display: inline-block;
      margin: 0; }
      #activities .slick-dots li button {
        position: relative;
        text-indent: -9999px; }
        #activities .slick-dots li button:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          background-color: #d8d8d8; }
      #activities .slick-dots li.slick-active button:before {
        background-color: #0ea7ad; }
  /* Slider */
  .slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent; }
  .slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0; }
    .slick-list:focus {
      outline: none; }
    .slick-list.dragging {
      cursor: pointer;
      cursor: hand; }
  .slick-slider .slick-track,
  .slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  .slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto; }
    .slick-track:before, .slick-track:after {
      content: "";
      display: table; }
    .slick-track:after {
      clear: both; }
    .slick-loading .slick-track {
      visibility: hidden; }
  .slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    display: none; }
    [dir="rtl"] .slick-slide {
      float: right; }
    .slick-slide img {
      display: block; }
    .slick-slide.slick-loading img {
      display: none; }
    .slick-slide.dragging img {
      pointer-events: none; }
    .slick-initialized .slick-slide {
      display: block; }
    .slick-loading .slick-slide {
      visibility: hidden; }
    .slick-vertical .slick-slide {
      display: block;
      height: auto;
      border: 1px solid transparent; }
  .slick-arrow.slick-hidden {
    display: none; } }

@media print, screen and (min-width: 801px) {
  .main_img {
    background: url(../images/home/top_main_pc.jpg) no-repeat;
    width: 100%;
    height: 854px;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center; }
  #contents {
    padding-top: 90px; }
  h2 {
    color: #028287;
    font-weight: bold;
    line-height: 1.3;
    position: relative;
    text-align: center;
    font-size: 38px;
    padding-bottom: 20px; }
    h2:before {
      content: "";
      height: 2px;
      background-color: #0ea7ad;
      position: absolute;
      bottom: 0;
      width: 120px;
      left: calc(50% - 60px); }
  .news_list {
    margin-top: 50px; }
    .news_list dl {
      overflow: hidden; }
      .news_list dl:not(:first-of-type) {
        margin-top: 30px; }
      .news_list dl dt {
        color: #666666;
        background-color: #eceff1;
        text-align: center;
        line-height: 1.2;
        padding: 6px;
        width: 100px;
        font-size: 16px;
        float: left; }
      .news_list dl dd {
        float: left;
        width: calc(100% - 100px);
        padding-left: 20px; }
        .news_list dl dd a {
          text-decoration: none;
          color: #000; }
          .news_list dl dd a:hover {
            text-decoration: underline; }
  .more_btn {
    margin-top: 60px;
    text-align: center; }
    .more_btn a {
      text-decoration: none;
      color: #028287;
      border: 1px solid #0ea7ad;
      display: inline-block;
      text-align: center;
      padding: 3px 15px 4px;
      min-width: 180px;
      transition: all .2s; }
      .more_btn a:hover {
        background-color: #0ea7ad;
        color: #fff; }
  #news .common_inner {
    max-width: 750px; }
  #introduction {
    background: url(../images/home/top_contents_img.jpg) no-repeat;
    width: 100%;
    height: 2115px;
    background-size: cover;
    background-position: center;
    padding-top: 630px;
    text-align: center;
    margin-top: -230px; }
    #introduction h2 + p {
      margin-top: 50px;
      line-height: 2; }
    #introduction .btn_list {
      margin-top: 60px; }
      #introduction .btn_list > div:not(:first-of-type) {
        margin-top: 10px; }
  #activities {
    margin-top: -530px;
    padding-bottom: 110px; }
    #activities .activities_slide {
      margin-top: 50px; }
      #activities .activities_slide .item {
        position: relative; }
        #activities .activities_slide .item .img img {
          max-width: 100%; }
        #activities .activities_slide .item .text {
          width: 310px;
          height: 240px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          position: absolute;
          right: 0;
          bottom: 0;
          background-color: #fff; }
          #activities .activities_slide .item .text .tuki {
            color: #028287;
            font-weight: bold;
            font-family: "Lato", sans-serif;
            display: inline-block;
            position: relative;
            font-size: 60px;
            padding-right: 30px;
            border-bottom: 1px solid #0ea7ad; }
            #activities .activities_slide .item .text .tuki:after {
              content: "月";
              font-weight: normal;
              position: absolute;
              bottom: 2px;
              right: 0px;
              font-size: 26px; }
          #activities .activities_slide .item .text .title {
            font-size: 24px;
            margin-top: 15px; }
          #activities .activities_slide .item .text .more_btn {
            margin-top: 20px; }
    #activities button {
      background-color: transparent;
      border: none;
      cursor: pointer;
      outline: none;
      padding: 0;
      appearance: none; }
    #activities .slick-prev {
      position: absolute;
      background: url(../images/common/slide_arrow_left_pc.png) no-repeat;
      width: 36px;
      height: 65px;
      background-size: cover;
      left: -80px;
      top: calc(50% - 32px);
      z-index: 2; }
    #activities .slick-next {
      position: absolute;
      background: url(../images/common/slide_arrow_right_pc.png) no-repeat;
      width: 36px;
      height: 65px;
      background-size: cover;
      right: -80px;
      top: calc(50% - 32px);
      z-index: 2; }
    #activities .slick-dots {
      margin-top: 20px; }
      #activities .slick-dots li {
        display: inline-block;
        margin: 0;
        width: 68px;
        height: 7px; }
        #activities .slick-dots li:not(:first-of-type) {
          margin-left: 12px; }
        #activities .slick-dots li button {
          position: relative;
          text-indent: -9999px; }
          #activities .slick-dots li button:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            background-color: #d8d8d8;
            width: 68px;
            height: 7px; }
        #activities .slick-dots li.slick-active button:before {
          background-color: #0ea7ad; }
  /* Slider */
  .slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent; }
  .slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0; }
    .slick-list:focus {
      outline: none; }
    .slick-list.dragging {
      cursor: pointer;
      cursor: hand; }
  .slick-slider .slick-track,
  .slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  .slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto; }
    .slick-track:before, .slick-track:after {
      content: "";
      display: table; }
    .slick-track:after {
      clear: both; }
    .slick-loading .slick-track {
      visibility: hidden; }
  .slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    display: none; }
    [dir="rtl"] .slick-slide {
      float: right; }
    .slick-slide img {
      display: block; }
    .slick-slide.slick-loading img {
      display: none; }
    .slick-slide.dragging img {
      pointer-events: none; }
    .slick-initialized .slick-slide {
      display: block; }
    .slick-loading .slick-slide {
      visibility: hidden; }
    .slick-vertical .slick-slide {
      display: block;
      height: auto;
      border: 1px solid transparent; }
  .slick-arrow.slick-hidden {
    display: none; } }
