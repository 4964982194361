.main_img{
  @if $type == sp{
    background: url(../images/home/top_main_sp.png) no-repeat;
    width: 100%;
    height: 496px;
    background-size: cover;
    background-position: center;
    text-align: center;
    padding-top: 146px;
    img{
      width: 111px;
    }
  }
  @if $type == pc{
    background: url(../images/home/top_main_pc.jpg) no-repeat;
    width: 100%;
    height: 854px;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

#contents{
  @if $type == sp{
    padding-top: 55px;
  }
  @if $type == pc{
    padding-top: 90px;
  }
}
h2{
  color: #028287;
  font-weight: bold;
  line-height: 1.3;
  position: relative;
  text-align: center;
  @if $type == sp{
    font-size: 23px;
    padding-bottom: 10px;
  }
  @if $type == pc{
    font-size: 38px;
    padding-bottom: 20px;
  }
  &:before{
    content: "";
    height: 2px;
    background-color: $c_base;
    position: absolute;
    bottom: 0;
    @if $type == sp{
      width: 80px;
      left: calc(50% - 40px);
    }
    @if $type == pc{
      width: 120px;
      left: calc(50% - 60px);
    }
  }
}
.news_list{
  @if $type == sp{
    margin-top: 35px;
  }
  @if $type == pc{
    margin-top: 50px;
  }
  dl{
    @if $type == sp{
      &:not(:first-of-type){
        margin-top: 25px;
      }
    }
    @if $type == pc{
      overflow: hidden;
      &:not(:first-of-type){
        margin-top: 30px;
      }
    }
    dt{
      color: #666666;
      background-color: #eceff1;
      text-align: center;
      line-height: 1.2;
      padding: 6px;
      @if $type == sp{
        display: inline-block;
        font-size: 14px;
        width: 100px;
      }
      @if $type == pc{
        width: 100px;
        font-size: 16px;
        float: left;
      }
    }
    dd{
      @if $type == sp{
        margin-top: 10px;
      }
      @if $type == pc{
        float: left;
        width: calc(100% - 100px);
        padding-left: 20px;
      }
      a{
        text-decoration: none;
        color: #000;
        &:hover{
          text-decoration: underline;
        }
      }
    }
  }
}
.more_btn{
  @if $type == sp{
    margin-top: 40px;
    text-align: center;
  }
  @if $type == pc{
    margin-top: 60px;
    text-align: center;
  }
  a{
    text-decoration: none;
    color: #028287;
    border: 1px solid $c_base;
    display: inline-block;
    text-align: center;
    @if $type == sp{
      padding: 10px 15px;
      width: 60%;
    }
    @if $type == pc{
      padding: 3px 15px 4px;
      min-width: 180px;
      transition: all .2s;
      &:hover{
        background-color: $c_base;
        color: #fff;
      }
    }
  }
}
#news{
  @if $type == sp{
  }
  @if $type == pc{
    .common_inner{
      max-width: 750px;
    }
  }
}

#introduction{
  @if $type == sp{
  }
  @if $type == pc{
    background: url(../images/home/top_contents_img.jpg) no-repeat;
    width: 100%;
    height: 2115px;
    background-size: cover;
    background-position: center;
    padding-top: 630px;
    text-align: center;
    margin-top: -230px;
  }
  h2 + p{
    @if $type == sp{
      margin-top: 30px;
      line-height: 2;
    }
    @if $type == pc{
      margin-top: 50px;
      line-height: 2;
    }
  }
  .btn_list{
    @if $type == sp{
      margin-top: 40px;
      text-align: center;
    }
    @if $type == pc{
      margin-top: 60px;
    }
    > div:not(:first-of-type){
      margin-top: 10px;
    }
  }
}

#activities{
  @if $type == sp{
    padding-bottom: 50px;
  }
  @if $type == pc{
    margin-top: -530px;
    padding-bottom: 110px;
  }
  .activities_slide{
    @if $type == sp{
      margin-top: 35px;
    }
    @if $type == pc{
      margin-top: 50px;
    }
    .item{
      @if $type == sp{
      }
      @if $type == pc{
        position: relative;
      }
      .img{
        img{
          max-width: 100%;
        }
      }
      .text{
        @if $type == sp{
          background-color: #eceff1;
          text-align: center;
          padding: 20px 15px 25px;
        }
        @if $type == pc{
          width: 310px;
          height: 240px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          position: absolute;
          right: 0;
          bottom: 0;
          background-color: #fff;
        }
        .tuki{
          color: #028287;
          font-weight: bold;
          font-family: $ff_eng;
          display: inline-block;
          position: relative;
          @if $type == sp{
            font-size: 35px;
            padding-right: 30px;
          }
          @if $type == pc{
            font-size: 60px;
            padding-right: 30px;
            border-bottom: 1px solid $c_base;
          }
          &:after{
            content: "月";
            font-weight: normal;
            position: absolute;
            bottom: 2px;
            right: 0px;
            @if $type == sp{
              margin-right: 10px;
              font-size: 15px;
            }
            @if $type == pc{
              font-size: 26px;
            }
          }
        }
        .title{
          @if $type == sp{
            display: inline-block;
            padding-bottom: 3px;
          }
          @if $type == pc{
            font-size: 24px;
            margin-top: 15px;
          }
        }
        .more_btn{
          @if $type == sp{
            margin-top: 15px;
          }
          @if $type == pc{
            margin-top: 20px;
          }
        }
      }
    }
  }
  button{
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    padding: 0;
    appearance: none;
  }
  .slick-prev{
    position: absolute;
    @if $type == sp{
      background: url(../images/common/slide_arrow_left_sp.png) no-repeat;
      width: 35px;
      height: 35px;
      background-size: cover;
      left: -8px;
      top: calc(20%);
      z-index: 2;
    }
    @if $type == pc{
      background: url(../images/common/slide_arrow_left_pc.png) no-repeat;
      width: 36px;
      height: 65px;
      background-size: cover;
      left: -80px;
      top: calc(50% - 32px);
      z-index: 2;
    }
  }
  .slick-next{
    position: absolute;
    @if $type == sp{
      background: url(../images/common/slide_arrow_right_sp.png) no-repeat;
      width: 35px;
      height: 35px;
      background-size: cover;
      right: -8px;
      top: calc(20%);
      z-index: 2;
    }
    @if $type == pc{
      background: url(../images/common/slide_arrow_right_pc.png) no-repeat;
      width: 36px;
      height: 65px;
      background-size: cover;
      right: -80px;
      top: calc(50% - 32px);
      z-index: 2;
    }
  }
  .slick-dots{
    margin-top: 20px;
    @if $type == sp{
      margin-top: 10px;
    }
    li{
      display: inline-block;
      margin: 0;
      @if $type == sp{
        width: 50px;
        height: 7px;
        margin-right: 6px;
      }
      @if $type == pc{
        width: 68px;
        height: 7px;
        &:not(:first-of-type){
          margin-left: 12px;
        }
      }
      button{
        position: relative;
        text-indent: -9999px;
        &:before{
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          background-color: #d8d8d8;
          @if $type == sp{
            width: 50px;
            height: 7px;
          }
          @if $type == pc{
            width: 68px;
            height: 7px;
          }
        }
      }
      &.slick-active{
        button:before{
          background-color: $c_base;
        }
      }
    }
  }
}


.sample{
  @if $type == sp{
  }
  @if $type == pc{
  }
}
